.shortner-form-container{
    display: flex;
    justify-content: center;
    /* height: 90vh; */
}


.input-text-lable{
    width: 100%;
    text-align: end;
    color: #253437;
    margin-right: 1rem;
}

.input-text-error{
    width: 100%;
    text-align: start;
    color: rgb(255, 0, 0);
    padding-left: 6vw;
}

.input-text-error-none{
    display: none;
}

.custom-domain-lable{
    width: 100%;
    text-align: start;
    color: rgba(211, 117, 245, 0.685);
    padding-left: 6vw;
}

.input-text{
    background-color: #e6eefa39;
    width: 88%;
    margin-right: 2rem;
}

.input-text .p-inputtext{
    background-color: #e6eefa39;

}

.form-header {
    display: flex;
    margin: 2rem;
    justify-content: space-between;
    align-items: center; /* Center the content vertically */
}

.form-header h3 {
    margin: 0; /* Remove default margin for h3 */
    flex: 1; /* Allow the h3 to take available space and center itself */
}

.stats-button {
    margin-left: 1rem;
    background-color: #464d4e;
    border: #464d4e;
}

.stats-button:hover{
    background-color: #1d292b!important;
    border: #1d292b;
}

.form-footer{
    /* display: flex; */
    /* justify-content: start; */
    background-color: #e6eefa;
    width: 100%;
}

.shorten-button{
    width: 100%;
    background-color: #464d4e;
    border: #464d4e;
}

.shorten-button:hover{
    background-color: #1d292b!important;
    border: #1d292b;
}

.full-url-text{
    border-radius: 15px;
    border: 1px solid grey;
    background-color: white;
    width: 100%;
    /* margin-right: 2rem; */
}

@media (min-width: 1200px) {
    .shortner-form {
        /* margin: .5rem; */
        /* padding: 2rem; */
        border: 1px solid;
        border-radius: 5px;
        min-width: min-content;
        width: 100%;
        height: 100%;
        overflow: auto;
    }
  }
  
  @media (max-width: 767px) {
    .shortner-form {
        /* margin: .5rem; */
        /* padding: 2rem; */
        border: 1px solid;
        border-radius: 5px;
        min-width: min-content;
        width: 100%;
        height: 100%;
        overflow: auto;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1200px) {
    .shortner-form {
        /* margin: .5rem; */
        /* padding: 2rem; */
        border: 1px solid;
        border-radius: 5px;
        min-width: min-content;
        width: 100%;
        height: 100%;
        overflow: auto;
    }
  }

  .margin-bottom{
    margin-bottom: 3rem!important;
  }

  .p-dropdown-label {
    /* display: block; */
    white-space: nowrap;
    overflow: hidden;
    flex: 1 1 auto;
    width: 1%;
    text-overflow: ellipsis;
    cursor: pointer;
    display: flex!important;
}

.full-width {
    width: 100%!important;
}

.copy-icon{
    color: black!important;
}

.copy-icon:hover{
    color: #003fff!important;
    cursor: pointer;
}